import {Component} from 'react';
import configureDI from '../../container';
import {TYPES} from '../../types';

export default class AbstractPage extends Component {
    api = null

    constructor(props) {
        super(props)
        this.init()
    }

    async init() {
        const container = await configureDI()
        this.api = container.get(TYPES.Api)
        try {
            const response = await this.api.get_user()
            const agrees = response.data.data.user.agrees;

            this.setState({user: response.data.data.user})

            for(let i = 0; i < agrees.length; i++) {
                if(agrees[i].agree_type.slug === 'privacy_policy_rc') {
                    if(agrees[i].status === 0 && window.location.pathname !== '/cadastro') {
                        window.location.href = '/cadastro'
                    }
                }
            }

            if(agrees.length === 0 && window.location.pathname !== '/cadastro') {
                window.location.href = '/cadastro'
            }
        } catch (e) {
            this.props.history.push("/auth");
        }
    }
}
