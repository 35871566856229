import { BrowserRouter, Switch, Route} from 'react-router-dom';

// Páginas
import Account from './components/pages/account/account';
import Auth from './components/pages/auth/auth';
import Home from './components/pages/home/home';
import Register from './components/pages/register/register';

import Logout from "./components/logout/logout";

function App() {
  return (
    <BrowserRouter>
        {/* <NavBar /> */}
        <Switch>
            <Route path="/" exact={true} component={Home} />
            <Route path="/auth" component={Auth} />
            <Route path="/atualizar-cadastro" component={Account} />
            <Route path="/minha-conta" children={<Register account={true} />} />
            <Route path="/cadastro" component={Register} />
            <Route path="/sair" component={Logout} />
        </Switch>
      </BrowserRouter>
  );
}

export default App;
