import React, { Component } from 'react';
import style from './modal.scss';

//Images
import Logo from '../../../assets/images/logo_incrivel.png';

class Modal extends Component {
    constructor(props, context){
        super(props, context);
        this.modalOpen = this.modalOpen.bind(this);
        this.modalClose = this.modalClose.bind(this);
        this.state = {
            modalState : 'modal',
        }
    }

    modalOpen(){
        if(this.state.modalState === 'modal'){
            this.setState({modalState: 'modalOpen'})
        }
    }

    modalClose(){
        if(this.state.modalState !== 'modal'){
            this.setState({modalState: 'modal'})
        }
    }

    render() {
        const messages = this.props.messages || [];
        return (
            <>
                <div className={this.state.modalState} tabIndex="-1">
                    <div className="modal-dialog">
                        <div className="modal-content d-flex justify-content-center align-items-center">
                            <div className="modal-header">
                                <img src={Logo} className="img-fluid" />
                                <button type="button" onClick={this.modalClose} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <ul>
                                {messages.map((message, i) => {
                                    return <li key={"message-" + i}>{message}</li>
                                })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Modal;
