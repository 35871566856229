import React from 'react';
import AbstractPage from "../pages/AbstractPage";
import {logout} from "../../auth";
import {Redirect} from 'react-router-dom'

export default class Logout extends AbstractPage {
    componentDidMount() {
        logout();
    }

    render() {
        return (
            <Redirect to={"/auth"} />
        )
    }
}
