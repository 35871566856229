import Cookies from 'js-cookie';

const ACCESS_TOKEN_KEY = "acc";
const REFRESH_TOKEN_KEY = "rtk";
export const isAuthenticated = async () => {
    const token = Cookies.get(ACCESS_TOKEN_KEY)
    if(token === undefined) {
        return false
    }

    return true;
}
export const getAccessToken = () => Cookies.get(ACCESS_TOKEN_KEY)
export const login = (accessToken, refreshToken = null) => {
    Cookies.set(ACCESS_TOKEN_KEY, accessToken)
    Cookies.set(REFRESH_TOKEN_KEY, refreshToken)
}

export const logout = () => {
    Cookies.remove(ACCESS_TOKEN_KEY)
    Cookies.remove(REFRESH_TOKEN_KEY)
}
