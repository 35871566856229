export default class Api {
  constructor(axios) {
    this.axios = axios;
  }

  async post_auth(username, password) {
    return this.axios.post('auth', {
      username: username,
      password: password
    });
  }

  async post_recover(username, password) {
    return this.axios.post('new-password', {
      email: username
    });
  }

  async get_user() {
    return this.axios.get('user');
  }

  async put_register(data) {
    return this.axios.put('user', data);
  }

  async put_user(data) {
    return this.axios.put('user', data);
  }

  async put_user_account(data) {
    return this.axios.put('user-account', data);
  }

  async get_media() {

  }
}
