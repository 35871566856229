import style from './footer.module.scss';
import React, { Component } from 'react';
import LogoFooter1 from '../../../assets/images/Logo_PetModerno.png';
import LogoFooter2 from '../../../assets/images/logo-login.png';
import AbstractPage from "../../pages/AbstractPage";
import LogoPetModerno from "../../../assets/images/Logo_PetModerno.png";

export default class Footer extends AbstractPage {
    constructor(props) {
        super(props);
        this.state = {
            user: {
                name: '...',
                email: '...',
                phone: '...',
                type: 0,
                agrees: []
            },
            account: props.account,
            errorMessages: [],
            password: '',
            passwordConfirmation: '',
            checkPrivacyPolicyRC: false,
            checkPrivacyPolicy48: false,
            checkOptinSMS: false,
            checkOptinEmail: false,
            checkOptinWhatsApp: false,
            checkOptinCall: false
        }
    }

    async componentDidMount() {
        await this.init();
    }
    render() {
        return (
            <footer>
                <div>
                    {
                        this.state.user.type === 1
                            ? <img src={LogoFooter1} />
                            : (
                                this.state.user.type === 2
                                    ? <img src={LogoFooter2} />
                                    : <img style={{height: '96px'}} />
                            )
                    }
                </div>
            </footer>
        )
    }
}
