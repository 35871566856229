import axios from "axios";
import DIContainer, {object} from "rsdi";
import Api from "./services/Api";
import {TYPES} from "./types";
import env from "react-dotenv";
import {getAccessToken} from "./auth";

async function configureDI() {
    const container = new DIContainer();
    const api = axios.create({
        baseURL: `${env.API_URL}`,
        withCredentials: getAccessToken() ? false : 'include'
    });

    api.interceptors.request.use(async config => {
        const token = getAccessToken();
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        config.headers.Accept = `application/json`;
        return config;
    });

    container.addDefinition(TYPES.Api, object(Api).construct(
        api
    ))

    return container
}

export default configureDI
