import style from './navBar.module.scss';
import React, { Component } from 'react'
import { NavLink } from 'react-router-dom';

//Logos
import LogoPetModerno from '../../../assets/images/Logo_PetModerno.png';
import LogoCoroa from '../../../assets/images/Logo_Coroa.png';
import Logo48 from '../../../assets/images/Logo_48_horas.png';
import LogoIncrivel from '../../../assets/images/logo_incrivel.png';
import AbstractPage from "../../pages/AbstractPage";

export default class NavBar extends AbstractPage {
    constructor(props) {
        super(props);
        this.state = {
            user: {
                name: '...',
                email: '...',
                phone: '...',
                type: 0,
                agrees: []
            },
            account: props.account,
            errorMessages: [],
            password: '',
            passwordConfirmation: '',
            checkPrivacyPolicyRC: false,
            checkPrivacyPolicy48: false,
            checkOptinSMS: false,
            checkOptinEmail: false,
            checkOptinWhatsApp: false,
            checkOptinCall: false
        }
    }

    async componentDidMount() {
        await this.init();
    }
    render() {
        return (
            <section className={style.fluid + ' container-fluid'}>
                <nav className={style.navBar + ' ' + style.containerDefault + " d-flex justify-content-between align-items-center"}>
                    <div className={style.logos}>
                        <NavLink to={"/"}>
                            {
                                this.state.user.type === 1
                                ? <img src={LogoPetModerno} className={"img-fluid " + style.img1} />
                                : (
                                    this.state.user.type === 2
                                    ? <img src={LogoCoroa} className={"img-fluid " + style.imgLogo} />
                                    : <img style={{height: '96px'}} />
                                )
                            }
                        </NavLink>
                    </div>
                    <div className={style.sair}>
                        <p>Olá {this.props.name}</p>
                        <div className="d-flex">
                            <div className={style.links}><NavLink to="/minha-conta"><i className="far fa-user m-1"></i>Meu Perfil</NavLink></div>
                            <div className={style.links}><NavLink to="/sair"><i className="far fa-times-circle m-1"></i>Sair</NavLink></div>
                        </div>
                    </div>
                    <div className={style.sairMobile}>
                        <div className={style.botao}>
                            <NavLink to="/minha-conta"><i className="far fa-user m-1"></i>Meu Perfil</NavLink>
                        </div>
                        <div className={style.botao}>
                            <NavLink to="/sair"><i className="far fa-times-circle m-1"></i>Sair</NavLink>
                        </div>
                    </div>
                </nav>
            </section>
        )
    }
}
